// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    calc_cycle_type: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "汇算周期不能为空"
      }
    ],
    alarm_level: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "汇算周期不能为空"
      }
    ],
    threshold: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "金额不能为空"
      },
      {
        trigger: "blur",
        type: "number",
        pattern: /^([0-9]+[\d]*(.[0-9]{1,2})?)$/,
        message: "注：仅允许填写数字，最多两位小数"
      }
    ],
    content: [
      {
        required: true,
        trigger: "blur",
        message: "预警语不能为空"
      }
    ]
  };

  return rules;
};
