<template>
  <div class="page-main-box" id="financeService">
    <!-- 左边栏 -->
    <div class="left-bar">
      <a-button
        class="add-btn"
        type="primary"
        @click="$router.push('/system/add-tax-source')"
        >添加税源地</a-button
      >
      <p class="mt-32 mb-16 ml-16 fz-16 fw-600">税源地</p>
      <ul class="city-list">
        <li
          v-for="item in tabList"
          :key="item.tax_source_code"
          :class="{ actived: item.tax_source_code === tabKey }"
          @click="handleTabChange(item.tax_source_code)"
        >
          {{ item.tax_source_name }}
        </li>
      </ul>
    </div>
    <!-- 右边栏 -->
    <div class="right-content">
      <!-- 税源地信息 -->
      <TaxSourceInfo class="mb-48" :source_code="tabKey"></TaxSourceInfo>
      <!-- 财税信息 -->
      <FinanceTaxInfo class="mb-48" :source_code="tabKey"></FinanceTaxInfo>
      <!-- 网络货运预警 -->
      <InternetWarning :source_code="tabKey"></InternetWarning>
    </div>
  </div>
</template>

<script>
import TaxSourceInfo from "./components/TaxSourceInfo/index";
import FinanceTaxInfo from "./components/FinanceTaxInfo/index";
import InternetWarning from "./components/InternetWarning/index";
import $await from "@/utils/await";
import { ref } from "vue";
import { useStore } from "vuex";
import { useTaxSourcesApi } from "@/apis/source";

export default {
  name: "service-order",
  components: { TaxSourceInfo, FinanceTaxInfo, InternetWarning },
  setup() {
    const store = useStore();

    const taxSourcesApi = useTaxSourcesApi();
    const tabList = ref([]);
    const tabKey = ref("");
    const dictionaries = ref({});

    // 获取字典枚举
    store.dispatch("dictionaries/getDictionaries").then(res => {
      dictionaries.value = res;
    });

    // 获取税源地列表
    (async () => {
      let [, res] = await $await(taxSourcesApi());
      if (res) {
        tabList.value = res;
        tabKey.value = tabList.value[0].tax_source_code;
      }
    })();

    // 切换tab
    const handleTabChange = key => {
      tabKey.value = key;
    };
    return { dictionaries, tabKey, handleTabChange, tabList };
  }
};
</script>

<style lang="less" scoped>
#financeService {
  position: relative;
  overflow: hidden;
  display: flex;
  .left-bar {
    width: 214px;
    background: #f8f8f8;
    height: 100%;
    position: fixed;
    .add-btn {
      width: 166px;
      height: 32px;
      margin: 24px auto 0;
      display: block;
    }
    .city-list {
      li {
        position: relative;
        height: 48px;
        line-height: 48px;
        padding-left: 24px;
        cursor: pointer;
      }
      .actived {
        background: #fff;
        &::after {
          content: "";
          width: 4px;
          height: 20px;
          position: absolute;
          left: 0;
          top: 12px;
          background: #007aff;
        }
      }
    }
  }
  .right-content {
    margin-left: 214px;
    padding: 32px 24px;
    width: calc(~"100% - 214px");
  }
}
</style>
