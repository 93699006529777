<template>
  <div class="tax-source-information-content">
    <div class="title">
      <p>税源地信息</p>
      <div class="line"></div>
      <p class="btn" @click="handleAddTaxSource">新增</p>
    </div>
    <!-- 列表 -->
    <vxe-table :data="tableData" show-overflow width="100%">
      <!-- 关联税务局 -->
      <vxe-table-column
        field="tax_bureau_name"
        title="关联税务局"
        min-width="460"
      ></vxe-table-column>

      <!-- 税务局数据标识 -->
      <vxe-table-column
        field="data_flag"
        title="税务局数据标识"
        min-width="176"
      >
        <template #header="{ column }">
          <span>{{ column.title }}</span>
          <Tooltip placement="bottom">
            <template #title>
              <span class="tips">
                数据范围为全国，数据标识相同的 税务局，数据相通
              </span>
            </template>
            <Icon class="mt-4 ml-4" name="iconxuanfuchutishi"></Icon>
          </Tooltip>
        </template>
        <template v-slot="{ row }">
          <Popover placement="rightTop" trigger="click">
            <template #content>
              <div class="pop-content" v-if="sameTaxBureaus.length">
                <p
                  class="fz-12"
                  v-for="(data, index) in sameTaxBureaus"
                  :key="data.id"
                >
                  {{ index + 1 + ". " + data.tax_bureau_name }}
                </p>
              </div>
              <div v-else class="pop-none">
                暂无数据相通的税务局
              </div>
            </template>
            <template #title>
              <span class="fz-12 pop-title">数据相同的税务局：</span>
            </template>
            <span
              style="text-decoration:underline; cursor:pointer;"
              @click="handleSameBureaus(row)"
            >
              {{ row.data_flag }}
            </span>
          </Popover>
        </template>
      </vxe-table-column>

      <!-- 是否需要反包税计算 -->
      <vxe-table-column
        field="calc_type_name"
        title="是否需要反包税计算"
        min-width="190"
      >
        <template #header="{ column }">
          <span>{{ column.title }}</span>
          <Tooltip placement="bottom">
            <template #title>
              <span class="tips">
                由于其他税费（个人所得税、附加 税费、印花税）的完税凭证抬头为
                司机个人，平台计算企业所得税时 无法进行税前列支，因此将其他税
                费纳入增值税的计税依据开具发票</span
              >
            </template>
            <Icon class="mt-4 ml-4" name="iconxuanfuchutishi"></Icon>
          </Tooltip>
        </template>
      </vxe-table-column>
      <vxe-table-column title="操作" width="70" fixed="right">
        <template v-slot="{ row }">
          <Tooltip placement="top" :destroyTooltipOnHide="true">
            <template #title>
              <p style="width:32px; text-align:center;">编辑</p>
            </template>
            <a><Icon name="iconbianji" @click="handleEdit(row)"></Icon></a>
          </Tooltip>
          <Tooltip placement="top" :destroyTooltipOnHide="true">
            <template #title>
              <p style="width:32px; text-align:center;">删除</p>
            </template>
            <a class="ml-16"
              ><Icon name="iconshanchu" @click="handleDelete(row)"></Icon
            ></a>
          </Tooltip>
        </template>
      </vxe-table-column>
    </vxe-table>

    <AddTaxSource
      v-if="visible"
      :source_code="source_code"
      :visible="visible"
      :actionType="actionType"
      :bureauInfo="bureauInfo"
      @getData="getTableData"
      @cancel="visible = false"
    ></AddTaxSource>

    <!-- 删除确认框 -->
    <xModal
      :w="288"
      :visible="deleteModal"
      :closable="false"
      @handleCancel="deleteModal = false"
      @handleOk="handleOk"
    >
      <template #content>
        <p class="text-center">确定要删除此税务局信息？</p>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { Popover, Tooltip, message } from "ant-design-vue";
import { tabList } from "../../config";
import AddTaxSource from "./components/AddTaxSource/index";
import xModal from "@/components/xModal";
import $await from "@/utils/await";
import {
  useTaxBureausApi,
  useTaxBureauByDataFlagApi,
  useDeleteTaxBureauApi
} from "@/apis/source";

export default {
  name: "TaxSourceInfo",
  components: { Popover, Tooltip, AddTaxSource, xModal },
  props: {
    source_code: { type: String, default: "" }
  },
  setup(props) {
    const taxBureauByDataFlagApi = useTaxBureauByDataFlagApi();
    const taxBureausApi = useTaxBureausApi();
    const deleteTaxBureauApi = useDeleteTaxBureauApi();
    // 新增税务局弹窗显示
    const visible = ref(false);
    // 删除弹窗显示
    const deleteModal = ref(false);
    // 列表数据
    const tableData = ref([]);
    // 根据税务局数据标识查询相同的税务局信息列表
    const sameTaxBureaus = ref([]);
    // 选中税务局信息
    const bureauInfo = ref({});
    // 选中税务局id
    const bureauId = ref("");
    // 编辑 / 添加
    const actionType = ref(0);

    const getTableData = async () => {
      if (props.source_code) {
        let [, res] = await $await(
          taxBureausApi({ tax_source_code: props.source_code })
        );
        if (res) {
          tableData.value = res;
        }
      }
    };

    // 获取税务局列表
    watchEffect(() => {
      getTableData();
    });

    // 查看相同税务局标识的税务局
    const handleSameBureaus = async row => {
      const [, res] = await $await(
        taxBureauByDataFlagApi({
          data_flag: row.data_flag,
          tax_bureau_code: row.tax_bureau_code
        })
      );
      if (res) {
        sameTaxBureaus.value = res;
      }
    };

    // 添加税务局
    const handleAddTaxSource = () => {
      actionType.value = 1;
      visible.value = true;
    };
    // 编辑税务局
    const handleEdit = row => {
      bureauInfo.value = row;
      actionType.value = 2;
      visible.value = true;
    };

    // 删除某个税务局
    const handleDelete = row => {
      bureauId.value = row.id;
      deleteModal.value = true;
    };

    // 删除
    const handleOk = async () => {
      const [, res] = await $await(deleteTaxBureauApi({ id: bureauId.value }));
      if (res) {
        deleteModal.value = false;
        getTableData();
        message.success("删除成功！");
      }
    };

    return {
      tabList,
      tableData,
      bureauInfo,
      actionType,
      sameTaxBureaus,
      visible,
      deleteModal,
      handleEdit,
      handleAddTaxSource,
      handleOk,
      handleDelete,
      handleSameBureaus,
      getTableData
    };
  }
};
</script>

<style lang="less" scoped>
.tax-source-information-content {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #363636;
    margin-bottom: 16px;
    .line {
      border-top: 1px solid #d4d4d4;
      flex: 1;
      margin: 0 8px;
      transform: scaleY(0.5);
    }
    .btn {
      cursor: pointer;
      color: #007aff;
    }
  }
}
.pop-content {
  width: 280px;
  p {
    margin-bottom: 16px;
  }
}
.pop-none {
  height: 100%;
  padding: 0 50px;
  font-size: 12px;
  color: #c1c1c1;
  white-space: nowrap;
}
.tips {
  width: 204px;
  font-size: 12px;
  display: block;
}
</style>
