<template>
  <div class="finance-tax-content">
    <div class="title">
      <p>财税信息</p>
      <div class="line"></div>
      <p class="btn" @click="handleEditOrAdd">编辑</p>
    </div>
    <!-- 列表 -->
    <vxe-table
      :data="tableData"
      show-overflow
      width="100%"
      :cell-class-name="cellClassName"
    >
      <vxe-table-column field="tax_code_name" title="税种" min-width="144">
      </vxe-table-column>
      <vxe-table-column field="tax_base_name" title="税基" min-width="168">
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.tax_base_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column field="rate_name" title="税率" min-width="112">
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.rate_name.split(',')"
            :key="index"
          >
            <span>{{ item }}</span>
            <Tooltip
              placement="top"
              v-if="row.tax_levy_way === 1 && row.tax_payment_way === 2"
            >
              <template #title>
                <span class="tips">核定征收率</span>
              </template>

              <Icon
                class="ml-4"
                name="iconxuanfuchutishi"
                height="16"
                width="16"
              ></Icon>
            </Tooltip>
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column field="reduction_name" title="惠普减免" min-width="128">
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.reduction_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="actual_rate_name"
        title="实际税率"
        min-width="128"
      >
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.actual_rate_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column field="threshold_name" title="起征点" min-width="112">
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.threshold_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="threshold_period_name"
        title="汇算周期"
        min-width="122"
      >
        <template v-slot="{ row }">
          <p
            class="line-height"
            v-for="(item, index) in row.threshold_period_name.split(',')"
            :key="index"
          >
            {{ item }}
          </p>
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="grading_desc"
        title="月平均销售额分档"
        min-width="148"
        v-if="show_grading_desc"
      >
        <template v-slot="{ row }">
          <div v-if="row.grading_desc">
            <p
              class="line-height"
              v-for="(item, index) in row.grading_desc.split(',')"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
          <p v-else>-</p>
        </template>
      </vxe-table-column>
    </vxe-table>

    <EditInfo
      v-if="visible"
      :visible="visible"
      :source_code="source_code"
      @getData="getTableData"
      @cancel="visible = false"
    ></EditInfo>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { Tooltip } from "ant-design-vue";
import EditInfo from "./components/EditInfo/index";
import $await from "@/utils/await";
import { useTaxRateConfigQueryApi } from "@/apis/source";

export default {
  name: "FinanceTaxInfo",
  components: { EditInfo, Tooltip },
  props: {
    source_code: { type: String, default: "" }
  },
  setup(props) {
    const taxRateConfigQueryApi = useTaxRateConfigQueryApi();
    const visible = ref(false);
    const tableData = ref([]);

    const show_grading_desc = ref(false);

    const getTableData = async () => {
      if (props.source_code) {
        let [, res] = await $await(
          taxRateConfigQueryApi({ tax_source_code: props.source_code })
        );
        if (res) {
          tableData.value = res;

          // 判断是否显示「月平均销售额分档」列
          const row = tableData.value.find(
            item => item.tax_code_name === "个人所得税"
          );
          if (row.grading_desc) {
            show_grading_desc.value = true;
          } else {
            show_grading_desc.value = false;
          }
        }
      }
    };

    // 获取税务局列表
    watchEffect(() => {
      getTableData();
    });

    const handleEditOrAdd = () => {
      visible.value = true;
      // 清空汇算周期缓存数据
      localStorage.removeItem("threshold_period_edit");
    };

    // 个人所得税样式修改
    const cellClassName = ({ row, column }) => {
      if (column.property === "tax_code_name") {
        if (
          row.tax_code_name === "个人所得税" &&
          row.tax_base_name.indexOf(",") != -1
        ) {
          return "vertical-align-top";
        }
      }
      return null;
    };

    return {
      tableData,
      show_grading_desc,
      getTableData,
      visible,
      cellClassName,
      handleEditOrAdd
    };
  }
};
</script>

<style lang="less" scoped>
.finance-tax-content {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #363636;
    margin-bottom: 16px;
    .line {
      border-top: 1px solid #d4d4d4;
      flex: 1;
      margin: 0 8px;
      transform: scaleY(0.5);
    }
    .btn {
      cursor: pointer;
      color: #007aff;
    }
  }
}
.pop-content {
  width: 280px;
  p {
    margin-bottom: 16px;
  }
}
.tips {
  width: 60px;
  font-size: 12px;
  display: block;
}
.line-height {
  line-height: 24px;
  display: flex;
  align-items: center;
  &:nth-child(1) {
    margin-top: 4px;
  }
  &:nth-last-of-type(1) {
    margin-bottom: 4px;
  }
}
</style>
<style lang="less">
.finance-tax-content {
  .vxe-cell {
    max-height: initial !important;
  }
  .vertical-align-top {
    vertical-align: top;
    padding-top: 4px;
  }
}
</style>
