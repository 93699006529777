<template>
  <div>
    <xModal
      title="财税信息"
      :visible="visible"
      :w="500"
      :footer="null"
      @handleCancel="handleCancel"
    >
      <template #content>
        <Form
          ref="formRef"
          :model="formState"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :rules="rules"
          class="form-box"
        >
          <!-- 税种 -->
          <FormItem label="税种" name="tax_code">
            <a-select
              v-model:value="formState.tax_code"
              placeholder="请选择"
              style="width:320px;"
            >
              <a-select-option
                :value="item.value"
                v-for="item in tax_code_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 税基 -->
          <FormItem label="税基" name="tax_base">
            <p v-if="tax_base_list.length === 1" class="input">
              {{ tax_base_list[0].label }}
            </p>
            <a-select
              v-if="tax_base_list.length && tax_base_list.length > 1"
              v-model:value="formState.tax_base"
              placeholder="请选择"
              style="width:320px;"
            >
              <a-select-option
                :value="item.value"
                v-for="item in tax_base_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 纳税方式 -->
          <FormItem
            label="纳税方式"
            name="tax_payment_way"
            v-if="formState.tax_code === '10106'"
          >
            <a-select
              v-model:value="formState.tax_payment_way"
              placeholder="请选择"
              style="width:320px;"
              @change="handleTaxPaymentWayChange"
            >
              <a-select-option
                :value="item.value"
                v-for="item in payment_way_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 征收方式 -->
          <FormItem
            label="征收方式"
            name="tax_levy_way"
            v-if="
              formState.tax_code === '10106' && formState.tax_payment_way === 2
            "
          >
            <div class="clude">
              <a-select
                v-model:value="formState.tax_levy_way"
                placeholder="请选择"
                style="width:320px;"
              >
                <a-select-option
                  :value="item.value"
                  v-for="item in levy_way_list"
                  :key="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
              <Popover
                placement="rightTop"
                trigger="hover"
                v-if="formState.tax_levy_way === 2"
              >
                <template #content>
                  <div class="pop-content">
                    <p class="fz-12 content">
                      月平均销售额：全年应纳税所得额分档/应税所得率/12
                    </p>
                    <ul class="left">
                      <p>经营所得使用税率</p>
                      <li>5%</li>
                      <li>10%</li>
                      <li>20%</li>
                      <li>30%</li>
                      <li>35%</li>
                    </ul>
                    <ul class="right">
                      <p>全年应纳税所得额分</p>
                      <li>3万及以内</li>
                      <li>3万至9万</li>
                      <li>9万至30万</li>
                      <li>30万至50万</li>
                      <li>50万以上</li>
                    </ul>
                  </div>
                </template>
                <Icon
                  class="icon"
                  name="iconxuanfuchutishi"
                  height="16"
                  width="16"
                ></Icon>
              </Popover>
            </div>
          </FormItem>

          <!-- 税率 -->
          <FormItem
            label="税率"
            name="rate"
            v-if="
              !(
                formState.tax_code === '10106' &&
                formState.tax_payment_way === 2
              )
            "
          >
            <a-select
              v-model:value="formState.rate"
              placeholder="请选择"
              style="width:320px;"
            >
              <a-select-option
                :value="item.value"
                v-for="item in rate_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 核定征收率 -->
          <FormItem
            label="核定征收率"
            name="deemed_levy_rate"
            v-if="
              formState.tax_code === '10106' &&
                formState.tax_payment_way === 2 &&
                formState.tax_levy_way === 1
            "
          >
            <a-select
              v-model:value="formState.deemed_levy_rate"
              placeholder="请选择"
              style="width:320px;"
            >
              <a-select-option
                :value="item.value"
                v-for="item in deemed_levy_rate_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 应税所得率 -->
          <FormItem
            label="应税所得率"
            name="taxable_income_rate"
            v-if="
              formState.tax_code === '10106' &&
                formState.tax_payment_way === 2 &&
                formState.tax_levy_way === 2
            "
          >
            <a-select
              v-model:value="formState.taxable_income_rate"
              placeholder="请选择"
              style="width:320px;"
            >
              <a-select-option
                :value="item.value"
                v-for="item in taxable_income_rate_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 普惠减免 -->
          <FormItem label="普惠减免" name="reduction">
            <a-select
              v-model:value="formState.reduction"
              placeholder="请选择"
              style="width:320px;"
              @change="handleReductionChange"
            >
              <a-select-option
                :value="item.value"
                v-for="item in reduction_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 实际税率 -->
          <FormItem
            label="实际税率"
            name="actual_rate"
            v-if="
              !(formState.tax_payment_way === 2 && formState.tax_levy_way === 2)
            "
          >
            <a-select
              v-if="formState.reduction === -1"
              v-model:value="formState.actual_rate"
              placeholder="请选择"
              style="width:320px;"
            >
              <a-select-option
                :value="item.value"
                v-for="item in actual_rate_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
            <p v-else class="input">
              {{
                parseFloat((formState.actual_rate_none * 100).toFixed(3)) + "%"
              }}
            </p>
          </FormItem>

          <!-- 起征点 -->
          <FormItem label="起征点" name="threshold">
            <a-select
              v-model:value="formState.threshold"
              placeholder="请选择"
              style="width:320px;"
            >
              <a-select-option
                :value="item.value"
                v-for="item in threshold_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 汇算周期 -->
          <FormItem label="汇算周期" name="threshold_period">
            <a-select
              v-model:value="formState.threshold_period"
              placeholder="请选择"
              style="width:320px;"
              @change="handleChangeThreshold"
            >
              <a-select-option
                :value="item.value"
                v-for="item in period_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
            <a-button @click="handleCancel">取消</a-button>
            <a-button class="ml-16" type="primary" @click="handleSubmit"
              >确定</a-button
            >
          </FormItem>
        </Form>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref, computed, watch, unref, watchEffect } from "vue";
import { useStore } from "vuex";
import { Form, Popover, message } from "ant-design-vue";
import xModal from "@/components/xModal";
import { getInvoiceRules } from "./config";
import {
  useTaxRateConfigSingleQueryApi,
  useTaxRateConfigUpdateApi
} from "@/apis/source";
import $await from "@/utils/await";
import { useChangeThreshold } from "./config";

export default {
  name: "finance-edit-info",
  props: {
    visible: { type: Boolean, default: false },
    source_code: { type: String, default: "" }
  },
  components: {
    xModal,
    Form,
    FormItem: Form.Item,
    Popover
  },
  setup(props, ctx) {
    const store = useStore();
    const taxRateConfigUpdateApi = useTaxRateConfigUpdateApi();
    const taxRateConfigSingleQueryApi = useTaxRateConfigSingleQueryApi();
    const isLoading = ref(false);
    const formRef = ref();

    // 税种
    let tax_code_list = store.getters.dictionaries.tax_type_list;
    let configuration;
    // 税基
    let tax_base_list = ref();
    // 税率
    let rate_list = ref();
    // 核定征收率
    let deemed_levy_rate_list = ref();
    // 应税所得率
    let taxable_income_rate_list = ref();
    // 纳税方式
    let payment_way_list = ref();
    // 征收方式
    let levy_way_list = ref();
    // 普惠减免
    let reduction_list = ref();
    // 起征点
    let threshold_list = ref();
    // 汇算周期
    let period_list = ref();
    // 实际税率
    let actual_rate_list = ref();

    // 表单信息
    const formState = ref({
      // 税种
      tax_code: tax_code_list[0].value
    });

    watch(
      () => formState.value.tax_code,
      async () => {
        configuration = store.getters.dictionaries.tax_type_config_list.find(
          tax => tax.tax_code === formState.value.tax_code
        );
        tax_base_list.value = configuration.tax_base_list;
        // 税率
        rate_list.value = configuration.rate_list;
        // 核定征收率
        deemed_levy_rate_list.value = configuration.deemed_levy_rate_list;
        // 应税所得率
        taxable_income_rate_list.value = configuration.taxable_income_rate_list;
        // 纳税方式
        payment_way_list.value = configuration.payment_way_list;
        // 征收方式
        levy_way_list.value = configuration.levy_way_list;
        // 普惠减免
        reduction_list.value = configuration.reduction_list;
        // 起征点
        threshold_list.value = configuration.threshold_list;
        // 汇算周期
        period_list.value = configuration.period_list;

        // 获取当前税的配置信息
        const [, res] = await $await(
          taxRateConfigSingleQueryApi({
            tax_source_code: props.source_code,
            tax_code: formState.value.tax_code
          })
        );
        if (res) {
          formState.value = { ...res };

          // 如果缓存中有汇算周期数据，则说明有更换过财税信息
          if (localStorage.getItem("threshold_period_edit")) {
            formState.value.threshold_period = +localStorage.getItem(
              "threshold_period_edit"
            );
          } else {
            localStorage.setItem("threshold_period_edit", res.threshold_period);
          }

          // 有普惠减免
          formState.value.actual_rate_none = computed(() => {
            if (
              // 个人所得税 + 核定征收
              formState.value.tax_code === "10106" &&
              formState.value.tax_payment_way === 2
            ) {
              return (
                formState.value.deemed_levy_rate -
                formState.value.deemed_levy_rate * formState.value.reduction
              );
            } else {
              return (
                formState.value.rate -
                formState.value.rate * formState.value.reduction
              );
            }
          });
        }
      },
      { immediate: true }
    );

    watchEffect(() => {
      // 增值税的实际税率枚举列表
      if (formState.value.tax_code === "10101") {
        actual_rate_list.value = store.getters.dictionaries.vat_actual_rates;
      }
      if (formState.value.tax_code === "10106") {
        if (formState.value.tax_payment_way === 1) {
          // 预征
          actual_rate_list.value =
            store.getters.dictionaries.pit_advance_levy_actual_rates;
        } else {
          // 核定征收
          actual_rate_list.value =
            store.getters.dictionaries.pit_deemed_levy_actual_rates;
        }
      }
    });

    const rules = getInvoiceRules(formState);

    // 提交表单
    const handleSubmit = () => {
      let data = { ...unref(formState) };
      // 用来区分实际税率是根据税率和普惠减免算出来的还是独立的
      if (formState.value.reduction !== -1) {
        data = {
          ...unref(formState),
          actual_rate: formState.value.actual_rate_none
        };
      }
      formRef.value
        .validate()
        .then(async () => {
          isLoading.value = true;
          const [, res] = await $await(taxRateConfigUpdateApi(data));
          if (res) {
            isLoading.value = false;
            message.success("修改成功！");
            handleCancel();
            ctx.emit("getData");
          } else {
            isLoading.value = false;
          }
        })
        .catch(error => {
          console.log("error", error);
        });
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };

    // 改变惠普减免，设定实际税率默认值
    const handleReductionChange = val => {
      if (val === -1) {
        if (formState.value.tax_code === "10101") {
          formState.value.actual_rate = 0.01;
        }
        if (formState.value.tax_code === "10106") {
          if (formState.value.tax_payment_way === 1) {
            formState.value.actual_rate = 0.005;
          } else {
            formState.value.actual_rate = 0.006;
          }
        }
      }
    };

    // 改变纳税方式，设定实际税率默认值
    const handleTaxPaymentWayChange = val => {
      // 预征
      if (val === 1) {
        formState.value.actual_rate = 0.005;
      } else {
        formState.value.actual_rate = 0.006;
      }
    };

    // 更改汇算周期
    const handleChangeThreshold = value => {
      useChangeThreshold(value, formState);
    };

    return {
      formRef,
      formState,
      rules,
      handleCancel,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      handleSubmit,
      configuration,
      tax_code_list,
      tax_base_list,
      rate_list,
      deemed_levy_rate_list,
      taxable_income_rate_list,
      reduction_list,
      threshold_list,
      period_list,
      payment_way_list,
      levy_way_list,
      actual_rate_list,
      handleReductionChange,
      handleTaxPaymentWayChange,
      handleChangeThreshold
    };
  }
};
</script>

<style lang="less" scoped>
.clude {
  position: relative;
  .icon {
    position: absolute;
    top: 12px;
    right: 36px;
  }
}
.input {
  background: #f5f5f5;
  border-radius: 4px;
  padding-left: 8px;
  height: 32px;
  width: 320px;
  line-height: 32px;
}
.tips {
  width: 204px;
  font-size: 12px;
  display: block;
}
.pop-content {
  width: 288px;
  font-size: 12px;
  .content {
    margin-bottom: 16px;
  }
  .left,
  .right {
    float: left;
    background: #f8fafc;
    width: 144px;
    li,
    p {
      height: 32px;
      line-height: 32px;
      text-align: center;

      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }
  }
}
</style>
<style lang="less">
.width-shot-option {
  width: 74px !important;
  min-width: 74px !important;
}
</style>
