<template>
  <div class="tax-source-information-content">
    <div class="title">
      <p>网络货运预警</p>
      <div class="line"></div>
      <p class="btn" @click="handleAddWarning">新增</p>
    </div>
    <!-- 列表 -->
    <vxe-table :data="tableData" show-overflow width="100%">
      <vxe-table-column
        field="calc_cycle_type_name"
        title="汇算周期"
        min-width="140"
      >
      </vxe-table-column>
      <vxe-table-column
        field="threshold_name"
        title="预警不含增值税金额"
        min-width="250"
      >
      </vxe-table-column>
      <vxe-table-column field="alarm_level_name" title="等级" min-width="147">
      </vxe-table-column>
      <vxe-table-column field="content" title="预警语" min-width="278">
        <template v-slot="{ row }">
          <div class="d-flex align-center">
            <p>
              {{
                row.content?.length > 16
                  ? row.content.toString().substr(0, 16) + "..."
                  : row.content
              }}
            </p>
            <Tooltip placement="top">
              <template #title>
                <span class="tips"> {{ row.content }}</span>
              </template>

              <Icon class="ml-4" name="icontishi"></Icon>
            </Tooltip>
          </div>
        </template>
      </vxe-table-column>
      <vxe-table-column field="address" title="操作" width="70" fixed="right">
        <template v-slot="{ row }">
          <Tooltip placement="top" :destroyTooltipOnHide="true">
            <template #title>
              <p style="width:32px; text-align:center;">编辑</p>
            </template>
            <a><Icon name="iconbianji" @click="handleEdit(row)"></Icon></a>
          </Tooltip>
          <Tooltip placement="top" :destroyTooltipOnHide="true">
            <template #title>
              <p style="width:32px; text-align:center;">删除</p>
            </template>
            <a class="ml-16"
              ><Icon name="iconshanchu" @click="handleDelete(row)"></Icon
            ></a>
          </Tooltip>
        </template>
      </vxe-table-column>
    </vxe-table>

    <!-- 添加预警弹窗 -->
    <AddWarning
      v-if="visible"
      :source_code="source_code"
      :actionType="actionType"
      :warningInfo="warningInfo"
      :visible="visible"
      @getData="getTableData"
      @cancel="visible = false"
    ></AddWarning>

    <!-- 删除确认框 -->
    <xModal
      :w="288"
      :visible="deleteModal"
      :closable="false"
      @handleOk="handleOk"
      @handleCancel="deleteModal = false"
    >
      <template #content>
        <p class="text-center">确定要删除此条预警记录？</p>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { Tooltip, message } from "ant-design-vue";
import { tabList } from "../../config";
import AddWarning from "./components/AddWarning/index";
import xModal from "@/components/xModal";
import $await from "@/utils/await";
import { useTaxAlarmConfigApi, useTaxAlarmDeleteApi } from "@/apis/source";

export default {
  name: "InternetWarning",
  components: { Tooltip, AddWarning, xModal },
  props: {
    source_code: { type: String, default: "" }
  },
  setup(props) {
    const taxAlarmDeleteApi = useTaxAlarmDeleteApi();
    const taxAlarmConfigApi = useTaxAlarmConfigApi();
    const visible = ref(false);
    const deleteModal = ref(false);

    const tableData = ref([]);

    // 编辑 / 添加
    const actionType = ref(0);
    // 预警id
    const warningId = ref("");
    //  预警信息
    const warningInfo = ref({});

    const getTableData = async () => {
      if (props.source_code) {
        let [, res] = await $await(
          taxAlarmConfigApi({ tax_source_code: props.source_code })
        );
        if (res) {
          tableData.value = res;
        }
      }
    };

    // 获取预警列表
    watchEffect(() => {
      getTableData();
    });

    // 添加预警
    const handleAddWarning = () => {
      actionType.value = 1;
      visible.value = true;
    };

    // 编辑预警
    const handleEdit = row => {
      warningInfo.value = row;
      actionType.value = 2;
      visible.value = true;
    };

    // 删除某个预警
    const handleDelete = row => {
      warningId.value = row.id;
      deleteModal.value = true;
    };

    // 删除
    const handleOk = async () => {
      const [, res] = await $await(taxAlarmDeleteApi({ id: warningId.value }));
      if (res) {
        deleteModal.value = false;
        getTableData();
        message.success("删除成功！");
      }
    };
    return {
      tabList,
      tableData,
      visible,
      actionType,
      warningId,
      warningInfo,
      getTableData,
      handleOk,
      handleEdit,
      handleAddWarning,
      handleDelete,
      deleteModal
    };
  }
};
</script>

<style lang="less" scoped>
.tax-source-information-content {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #363636;
    margin-bottom: 16px;
    .line {
      border-top: 1px solid #d4d4d4;
      flex: 1;
      margin: 0 8px;
      transform: scaleY(0.5);
    }
    .btn {
      cursor: pointer;
      color: #007aff;
    }
  }
}
.pop-content {
  width: 280px;
  p {
    margin-bottom: 16px;
  }
}
.tips {
  width: 204px;
  font-size: 12px;
  display: block;
}
</style>
