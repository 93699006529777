// import * as rule from "@wlhy-web-lib/rule/esm";
import { createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

export const getInvoiceRules = () => {
  const rules = {
    tax_code: [
      {
        type: "string",
        required: true,
        trigger: "blur",
        message: "税种不能为空"
      }
    ],
    tax_base: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "税基不能为空"
      }
    ],
    rate: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "税率不能为空"
      }
    ],
    deemed_levy_rate: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "核定征收率不能为空"
      }
    ],
    taxable_income_rate: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "应税所得率不能为空"
      }
    ],
    tax_payment_way: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "纳税方式不能为空"
      }
    ],
    tax_levy_way: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "征收方式不能为空"
      }
    ],
    reduction: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "普惠减免不能为空"
      }
    ],
    actual_rate: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "实际税率不能为空"
      }
    ],
    threshold: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "起征点不能为空"
      }
    ],
    threshold_period: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "汇算周期不能为空"
      }
    ]
  };

  return rules;
};

export const useChangeThreshold = (value, formState) => {
  Modal.confirm({
    title: "是否确定更改汇算周期",
    icon: createVNode(ExclamationCircleOutlined),
    content:
      "是否确定汇算更改周期，一旦更改将会联动更改其他税种的汇算周期，请谨慎操作！",
    okText: "确认",
    cancelText: "取消",
    onOk() {
      localStorage.setItem("threshold_period_edit", value);
    },
    onCancel() {
      localStorage.setItem(
        "threshold_period_edit",
        +localStorage.getItem("threshold_period_edit")
      );
      formState.value.threshold_period = +localStorage.getItem(
        "threshold_period_edit"
      );
    }
  });
};
