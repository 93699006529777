import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    ...rule.object({
      tax_source_code: [rule.required("税源地名称").blur()],
      calc_type: [rule.required("是否需要反包税").blur()],
      data_flag: [rule.required("税务局数据标识").blur()],
      tax_bureau_code: [rule.required("关联税务局").blur()]
    }),
    calc_type: [
      {
        type: "number",
        required: true,
        trigger: "blur",
        message: "是否需要反包税不能为空"
      }
    ]
  };

  return rules;
};
