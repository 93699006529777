<template>
  <div>
    <xModal
      title="添加预警"
      :visible="visible"
      :w="546"
      :footer="null"
      @handleCancel="handleCancel"
    >
      <template #content>
        <Form
          ref="formRef"
          :model="formState"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :rules="rules"
          class="form-box"
        >
          <!-- 汇算周期 -->
          <FormItem label="汇算周期" name="calc_cycle_type">
            <a-select
              v-model:value="formState.calc_cycle_type"
              placeholder="请选择"
              style="width:300px;"
            >
              <a-select-option
                :value="item.value"
                v-for="item in dictionaries.alarm_period_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 预警不含增值税金额 -->
          <FormItem label="预警不含增值税金额" name="threshold">
            <a-input-number
              v-model:value="formState.threshold"
              style="width:120px;"
              placeholder="请输入金额"
            /><span class="ml-8">万</span>
          </FormItem>

          <!-- 级别 -->
          <FormItem label="级别" name="alarm_level">
            <a-select
              v-model:value="formState.alarm_level"
              placeholder="请选择"
              style="width:300px;"
            >
              <a-select-option
                :value="item.value"
                v-for="item in dictionaries.alarm_level_list"
                :key="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </FormItem>

          <!-- 预警语 -->
          <FormItem label="预警语" name="content">
            <a-textarea
              placeholder="请输入"
              showCount
              :maxlength="50"
              :autoSize="{ minRows: 3, maxRows: 3 }"
              v-model:value="formState.content"
            />
          </FormItem>

          <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
            <a-button @click="handleCancel">取消</a-button>
            <a-button
              class="ml-16"
              type="primary"
              @click="handleSubmit"
              :loading="isLoading"
              >确定</a-button
            >
          </FormItem>
        </Form>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref } from "vue";
import { Form, message } from "ant-design-vue";
import { useStore } from "vuex";
import xModal from "@/components/xModal";
import { getInvoiceRules } from "../../config";
import $await from "@/utils/await";
import {
  useTaxAlarmConfigSaveApi,
  useTaxAlarmConfigUpdateApi
} from "@/apis/source";

export default {
  props: {
    source_code: { type: String, default: "" },
    visible: { type: Boolean, default: false },
    actionType: { type: Number, default: 0 },
    warningInfo: { type: Object, default: () => {} }
  },
  components: {
    xModal,
    Form,
    FormItem: Form.Item
  },
  setup(props, ctx) {
    const store = useStore();
    const taxAlarmConfigUpdateApi = useTaxAlarmConfigUpdateApi();
    const taxAlarmConfigSaveApi = useTaxAlarmConfigSaveApi();

    const isLoading = ref(false);
    const formRef = ref();
    const dictionaries = store.getters.dictionaries;

    // 表单信息
    const formState = ref({
      threshold:
        props.actionType === 2 ? props.warningInfo.threshold / 10000 : "",
      content: props.actionType === 2 ? props.warningInfo.content : "",
      alarm_level: props.actionType === 2 ? props.warningInfo.alarm_level : 1,
      calc_cycle_type:
        props.actionType === 2 ? props.warningInfo.calc_cycle_type : 0
    });

    const rules = getInvoiceRules(formState);

    // 提交表单
    const handleSubmit = () => {
      formState.value.threshold = formState.value.threshold * 10000;
      // 添加
      if (props.actionType === 1) {
        const data = {
          tax_source_code: props.source_code,
          ...formState.value
        };
        formRef.value
          .validate()
          .then(async () => {
            isLoading.value = true;
            const [, res] = await $await(taxAlarmConfigSaveApi(data));
            if (res) {
              isLoading.value = false;
              message.success("添加成功！");
              handleCancel();
              ctx.emit("getData");
            } else {
              isLoading.value = false;
            }
          })
          .catch(error => {
            console.log("error", error);
          });
        // 编辑
      } else {
        formRef.value.validate().then(async () => {
          const data = {
            id: props.warningInfo.id,
            ...formState.value
          };
          const [, res] = await $await(taxAlarmConfigUpdateApi(data));
          if (res) {
            isLoading.value = false;
            message.success("修改成功！");
            handleCancel();
            ctx.emit("getData");
          } else {
            isLoading.value = false;
          }
        });
      }
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };
    return {
      formRef,
      formState,
      isLoading,
      rules,
      dictionaries,
      handleCancel,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      handleSubmit
    };
  }
};
</script>

<style lang="less" scoped>
.clude {
  position: relative;
  .icon {
    position: absolute;
    top: 12px;
    right: 36px;
  }
}
.tips {
  width: 204px;
  font-size: 12px;
  display: block;
}
.pop-content {
  width: 280px;
  p {
    margin-bottom: 16px;
  }
}
</style>
<style lang="less">
.width-shot-option {
  width: 74px !important;
  min-width: 74px !important;
}
</style>
